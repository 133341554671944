<template>
  <v-card elevation="0">
    <VacateOrRemoveRecord
      :setWinsLoading="setWinsLoading"
      :loadRecordList="loadRecordList"
      :dialog="dialog"
      :record="selectedRecord"
      :setDialog="setDialog"
    />

    <div v-if="!winsLoading && !selectable">
      <v-card
        v-for="record in getRecordsForDates()"
        :key="record._id"
        class="pl10 mt10 mx-1 elevation-5 rounded"
      >
        <v-row justify="end">
          <v-menu
            min-width="90" offset-x left top origin="top"
            transition="slide-y-reverse-transition"
            content-class="elevation-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab v-bind="attrs" v-on="on"
                     class="transparent elevation-0 mr-7 mt-2"
                     :class="record.date < date ? 'grey--text' : 'teal--text'"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-row justify="center" class="my-4">
              <v-btn @click="dialogHandler(record)"
                     :dark="!light" fab :color="light ? 'white' : 'red'" elevation="5"
                     :class="light ? 'red--text' : ''">
                <v-icon>mdi-close-thick</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4" v-if="$route.path !== '/records-of-client'">
              <v-btn @click="recordClientHandler(record.subscriber)"
                     :dark="!light" fab :color="light ? 'white' : 'blue-grey darken-1'" elevation="5"
                     :class="light ? 'blue-grey text--darken-1' : ''">
                <v-icon>mdi-calendar-edit</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4">
              <v-btn :to="`/exchange-records?id=${record._id}`"
                     :dark="!light" fab :color="light ? 'white' : 'green'" elevation="5"
                     :class="light ? 'green--text' : ''">
                <v-icon>mdi-arrow-left-right-bold</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4" v-if="$route.path !== '/records-of-client'">
              <v-btn @click="$router.push(`/records-of-client?id=${record.subscriber._id}`)"
                     :dark="!light" fab :color="light ? 'white' : 'deep-purple lighten-2'" elevation="5"
                     :class="light ? 'deep-purple--text text--lighten-2' : ''">
                <v-icon>mdi-account-details</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4" v-if="$route.path !== '/records-of-client'">
              <v-btn :dark="!light" fab :color="light ? 'white' : 'teal'" elevation="5"
                     :href="`tel:${record.subscriber.phone}`"
                     :class="light ? 'teal--text' : ''">
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </v-row>
          </v-menu>
        </v-row>

        <PaymentButton
          v-if="!paid(record) && !paysLoading"
          style="position: absolute; bottom: 1em; right: 7px"
          :record="record"
          :set-wins-loading="setWinsLoading"
          :loadPaysForRecords="loadPaysForRecords"
          :date="date"
        />

        <v-icon
          v-if="paid(record) && !paysLoading"
          style="position: absolute; bottom: 20px; right: 24px"
          class="elevation-0 transparent "
          :class="record.date < date ? 'grey--text' : 'light-green--text text--accent-4'"
        >
          mdi-check-bold
        </v-icon>

        <v-progress-circular
          v-if="paysLoading"
          style="position: absolute; bottom: 20px; right: 24px"
          indeterminate
          :size="24"
          color="grey"
        ></v-progress-circular>

        <div style="margin-top: -45px">
          <v-card-text :class="record.date < date ? 'black--text' : 'teal--text text--lighten-1'">
            <b>{{ record.date | date }}</b> <b>{{ record.time }}</b>
          </v-card-text>
          <v-card-text style="margin-top: -20px">
            <div
              v-if="record.firstName && record.lastName
              && (record.firstName !== record.subscriber.firstName || record.lastName !== record.subscriber.lastName)"
            >
              <b>Клиент:</b> {{ record.subscriber.firstName }}
              {{ record.subscriber.lastName }}
              ({{ record.subscriber.phone }})
              <br>
              <b class="deep-orange--text">Записан:</b> {{ record.firstName }}
              {{ record.lastName }}
            </div>
            <div v-else>
              <b>Клиент:</b> {{ record.subscriber.firstName }}
              {{ record.subscriber.lastName }}
              ({{ record.subscriber.phone }})
            </div>
            <div>
              <b>Услуга:</b> {{ record.category.title }}
            </div>
            <div style="padding-right: 30px">
              <b>Процедуры:</b> {{ record.services.map(s => s.title).join(', ') }}
            </div>
            <div style="padding-right: 30px">
              <b>Стоимость:</b>
              <span v-if="!paid(record)"> {{ record.total }}</span>
              <span v-if="paid(record)" class="font-weight-bold" :class="record.date < date ? 'grey--text' : 'light-green--text text--accent-4'"
              > {{ getPaymentSumForRecord(record) }}</span>
            </div>
            <div v-if="record.comment" style="padding-right: 30px">
              <b :class="record.date < date ? 'black--text' : 'pink--text'">Комментарий: </b>
              <i>{{ record.comment }}</i>
            </div>
            <div v-if="record.notification_kind.length" style="padding-right: 30px">
              <b>Уведомление: </b>
              <b :class="record.date < date ? 'grey--text' : 'light-green--text text--accent-4'">{{ Array.from(new Set(record.notification_kind)).join(', ') }}</b>
            </div>

            <div>
              <MasterNoteForm
                class="mr-15"
                :start-value="record.masters_note"
                :loadRecordList="loadRecordList"
                :record_id="record._id"
              />
            </div>
          </v-card-text>
        </div>
      </v-card>
    </div>

    <v-row v-if="!winsLoading && selectable" class="mt10">
      <v-list-item-group v-model="item" color="teal">
        <v-list-item
          v-for="record in getRecordsForDates()"
          :key="record._id"
          three-line
          class="pl10 mt10 mx-1 elevation-3 rounded"
        >
          <v-list-item-content>
            <v-list-item-title>{{ record.date | date }} <b>{{ record.time }}</b></v-list-item-title>

            <v-list-item-subtitle
              v-if="record.firstName && record.lastName
              && (record.firstName !== record.subscriber.firstName || record.lastName !== record.subscriber.lastName)"
            >
              Клиент: {{ record.subscriber.firstName }}
              {{ record.subscriber.lastName }}
              ({{ record.subscriber.phone }})
              <br>
              Записан: {{ record.firstName }}
              {{ record.lastName }}
            </v-list-item-subtitle>

            <v-list-item-subtitle v-else>
              Клиент: {{ record.subscriber.firstName }}
              {{ record.subscriber.lastName }}
              ({{ record.subscriber.phone }})
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              Услуга: {{ record.category.title }}
              <br>
              Процедуры: {{ record.services.map(s => s.title).join(", ") }}
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              Стоимость: {{ record.total }}
              <br>
              <span v-if="record.comment" class="pink--text">Комментарий: {{ record.comment }}</span>
              <br>
              <span v-if="record.masters_note" class="teal--text">Заметка: {{ record.masters_note }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-row>

    <v-card-text
      v-if="!getRecordsForDates().length && !winsLoading"
      class="teal--text text-center"
    >
      Записи отсутствуют
    </v-card-text>

    <v-row v-if="winsLoading" justify="center" class="my-10">
      <v-progress-circular indeterminate color="teal"></v-progress-circular>
    </v-row>

  </v-card>
</template>

<script>
import VacateOrRemoveRecord from "./VacateOrRemoveRecord"
import dateFilter from "../filters/dateFilter";
import PaymentButton from "@/components/PaymentButton";
import MasterNoteForm from '@/components/MasterNoteForm'

export default {
  name: "RecordList",
  components: {MasterNoteForm, PaymentButton, VacateOrRemoveRecord},
  props: [
    "recordList",
    "dates",
    "winsLoading",
    "loadRecordList",
    "setWinsLoading",
    "setRecord",
    "selectable",
    "light"
  ],
  data: () => ({
    dialog: false,
    item: undefined,
    selectedRecord: "",
    date: "",
    paysLoading: false,
    pays: []
  }),
  computed: {
    records() {
      return this.recordList.filter(record => !record.open)
    },
  },
  methods: {
    dialogHandler(record) {
      this.selectedRecord = record
      this.dialog = true
    },

    getRecordsForDates() {
      if (!this.dates) {
        return this.records
      }
      let records = []
      this.dates.forEach(date => {
        this.records.forEach(r => {
          if (date === r.date) records.push(r)
        })
      })

      return records
    },

    setDialog(bool) {
      this.dialog = bool
    },

    recordClientHandler(client) {
      this.$store.commit("setFirstName", client.firstName)
      this.$store.commit("setLastName", client.lastName)
      this.$store.commit("setPhone", client.phone)
      this.$router.push("/choose-datetime-master")
    },

    async loadPaysForRecords() {
      this.paysLoading = true
      let records = this.recordList.map((r) => r._id)
      const json = await this.authFetch('/pay/load-pays-for-records', {records})
      if (json.status === 200) this.pays = json.pays
      this.paysLoading = false
    },

    paid(record) {
      if (this.pays) return this.pays.map(p => p.record._id).includes(record._id)
      else return false
    },

    getPaymentSumForRecord(record) {
      return this.pays.find(p => p.record._id === record._id).sum
    },

    payAcceptedHandler(pay) {
      this.pays.push(pay)
      this.pays.sort(this.sortPaysFunc)
      this.$emit('pay-accepted', pay)
    }
  },

  created() {
    this.eventHub.$on('pay-accepted', (e) => {
      this.payAcceptedHandler(e)
    })
  },

  async mounted() {
    this.date = dateFilter(Date.now(), "ISO")
    await this.loadPaysForRecords()
  },

  watch: {
    item: function (i) {
      if (this.setRecord && i !== undefined) {
        this.setRecord(this.getRecordsForDates()[i])
      }
      if (i === undefined) {
        this.setRecord(undefined)
      }
    },

    dates: function (newDate, oldDate) {
      if (this.setRecord && newDate.toString() !== oldDate.toString()) {
        this.setRecord(undefined)
      }
    },

    recordList() {
      this.loadPaysForRecords()
    }
  }
}
</script>

<style scoped>

</style>
