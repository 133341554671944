<template>
  <div>
    <v-form>
      <v-textarea
        v-model="note"
        label="Заметка мастера"
        rows="1"
        :prepend-icon="inputValueUpdated ? 'mdi-content-save' : 'mdi-pencil'"
        @click:prepend="saveHandler"
        :error-messages="inputValueUpdated ? 'Текст не сохранен' : ''"
        auto-grow
        :color="inputValueUpdated ? 'error' : 'teal'"
      ></v-textarea>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "MasterNoteForm",
  props: ['record_id', 'startValue', 'loadRecordList'],
  data: ()=>({
    note: ''
  }),

  computed: {
    inputValueUpdated() {
      if(!this.startValue && !this.note) return false
      if(!this.startValue && this.note && !this.note.trim().length) return false
      if(!this.startValue && this.note && this.note.trim().length) return true
      return this.note.trim() !== this.startValue.trim()
    },
  },

  methods: {

    async saveHandler() {
      if(!this.inputValueUpdated) return

      const json = await this.authFetch(
        '/record/add-masters-note',
        {_id: this.record_id, note: this.note.trim()}
      )

      if (json.status === 200) {
        await this.loadRecordList()
      }
    }
  },

  mounted() {
    this.note = this.startValue ?? ''
  }
}
</script>

<style scoped>

</style>
