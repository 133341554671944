<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="290"
  >
    <v-card>
      <v-card-title class="headline">
        Выберите действие
      </v-card-title>

      <v-card-text>
        Освободить окно или удалить запись вместе с окном?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="setDialog(false)" color="green darken-1" text>Отмена</v-btn>

        <v-btn small @click="vacateRecord(record)" color="amber" text>Освободить</v-btn>

        <v-btn small @click="deleteRecord(record)" color="error" text>Удалить</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "VacateOrRemoveRecord",
    props: ["dialog", "record", "setWinsLoading", "loadRecordList", "setDialog"],
    methods: {
      async deleteRecord(record) {
        const id = record._id
        this.setDialog(false)
        this.setWinsLoading(true)
        const json = await this.authFetch('/record/delete-record', {id})
        this.record = json.record
        this.setWinsLoading(false)
        await this.loadRecordList()
      },

      async vacateRecord(record) {
        const id = record._id
        const date = record.date
        const time = record.time
        this.setDialog(false)
        this.setWinsLoading(true)
        await this.authFetch('/record/vacate-record', {id, date, time})
        this.setWinsLoading(false)
        await this.loadRecordList()
      }
    }
  }
</script>

<style scoped>

</style>
