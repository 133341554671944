<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-7 mt-2 elevation-5"
          :class="record.date < date ? 'red black--text' : 'black--text yellow  accent-4'"
          small outlined
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-currency-rub</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text class="text-h6 black--text">
          Принять оплату в размере <span class="teal--text font-weight-bold">{{ sum | sum }}</span> ₽ ?
        </v-card-text>
        <v-text-field
          v-model="input"
          type="number"
          class="ml-5 mr-5"
          color="teal"
          label="Изменить сумму"
        ></v-text-field>

        <v-card-actions>
          <v-btn
            color="red darken-4"
            text
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="font-weight-bold"
            color="teal"
            text fab
            @click="registerPayHandler(sum)"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: "PaymentButton",
  props: [
    "record",
    "setWinsLoading",
    "date"
  ],
  data: () => ({
    dialog: false,
    input: "",
  }),
  computed: {
    sum() {
      if (this.input) return this.input
      else return this.record.total
    }
  },
  methods: {
    async registerPayHandler(sum) {
      if (this.setWinsLoading) this.setWinsLoading(true)

      const json = await this.authFetch(`/pay/register-pay-for-record`, {
        sum,
        recordId: this.record._id,
        date: this.record.date
      })

      if (json.status === 201) {
        this.eventHub.$emit('pay-accepted', json.pay)
      }
      if (this.setWinsLoading) this.setWinsLoading(false)
    }
  }
}
</script>
